import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';
import { NotificationContextProvider } from './subs/NotificationContextProvider';
import { UserContextProvider } from './subs/UserContextProvider';

export const ContentProvider: FC = ({ children }) => {
    const queryClient = new QueryClient();

    return (
        <UserContextProvider>
            <QueryClientProvider client={queryClient}>
                <NotificationContextProvider>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={'hr'}
                    >
                        {children}
                    </LocalizationProvider>
                </NotificationContextProvider>
            </QueryClientProvider>
        </UserContextProvider>
    );
};
