import { createContext, FC } from 'react';
import { useInitGoogleClient } from '../../../hooks/useInitGoogleClient';

export const UserContext = createContext({
    isSignedIn: false,
    data: { displayName: '', photos: [{ value: '' }] }
});

export const UserContextProvider: FC = ({ children }) => {
    const user = useInitGoogleClient();

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
