import axios from 'axios';
import { InvoiceBase } from '../interfaces';
import { Mail } from '../interfaces/mail.interface';

export const baseUrl = 'https://app.adria-field.hr/api';
export const api = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true
    },
    withCredentials: true
});

export function getInvoice(id?: number) {
    return api
        .get(typeof id === 'number' ? '/invoice/' + id : '/invoices')
        .then((res) => res.data);
}

export function getStatistics() {
    return api.get('/statistics').then((res) => res.data);
}

export function getApiVersion() {
    return api.get('/apiversion').then((res) => res.data);
}

export function getCompanyData() {
    return api.get('/company').then((res) => res.data);
}

export function postAddInvoice(data: InvoiceBase) {
    return api.post('/invoice', data).then((res) => res.data);
}

export function copyInvoice({ id }: { id: number }) {
    return api.post('/invoice/copy', { id });
}

export function patchInvoice(data: Partial<InvoiceBase>) {
    return api.patch('/invoice/' + data.id, data).then((res) => res.data);
}

export function deleteInvoice(id: number) {
    return api.delete(`/invoice/${id}`).then((res) => res.data);
}

export function getUserProfile() {
    return api.get('/auth/user').then((res) => {
        if (res.status === 200) return res.data;
    });
}

export function sendEmail(props: Mail) {
    return api.post('/mail/send', { ...props }).then((res) => {
        if (res.status === 200) return res.data;
    });
}
