import { CircularProgress } from '@mui/material';
import { lazy, StrictMode, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ContentProvider } from './components/content-provider/ContentProvider';
import ErrorBoundary from './ErrorBoundary';

const Layout = lazy(() => import('./components/layout/Layout'));
const Home = lazy(() => import('./pages/home/Home'));
const Invoices = lazy(() => import('./pages/invoices/Invoices'));
const NewInvoice = lazy(() => import('./pages/new-invoice/NewInvoice'));
const EditInvoice = lazy(() => import('./pages/edit-invoice/EditInvoice'));
const Statistics = lazy(() => import('./pages/statistics/Statistics'));
const Login = lazy(() => import('./pages/login/Login'));
const Profile = lazy(() => import('./pages/profile/Profile'));

function App() {
    return (
        <StrictMode>
            <ErrorBoundary>
                <ContentProvider>
                    <Suspense fallback={<CircularProgress />}>
                        <Router>
                            <Routes>
                                <Route element={<Layout />}>
                                    <Route
                                        path={'/'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <Home />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/invoices'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <Invoices />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/invoices/new'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <NewInvoice />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/invoice/:id'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <EditInvoice />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/statistics'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <Statistics />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/login'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <Login />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path={'/profile'}
                                        element={
                                            <Suspense
                                                fallback={<CircularProgress />}
                                            >
                                                <Profile />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                                {/*                                 <Route
                                    path="*"
                                    element={<Navigate to="/" replace={true} />}
                                /> */}
                            </Routes>
                        </Router>
                    </Suspense>
                </ContentProvider>
            </ErrorBoundary>
        </StrictMode>
    );
}

export default App;
