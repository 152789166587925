import { useEffect, useState } from 'react';
import { getUserProfile } from '../api';

export const useInitGoogleClient = () => {
    const [user, setUser] = useState({
        isSignedIn: false,
        data: { displayName: '', photos: [{ value: '' }] }
    });

    useEffect(() => {
        const userData = async () => {
            await getUserProfile()
                .then((res) => {
                    setUser(res);
                    console.log(res);
                })
                .catch((err) => console.log(err));
        };
        userData();
    }, []);

    return { ...user };
};
