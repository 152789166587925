import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/animations/error-animation.json';

export const Error = () => {
    return (
        <Stack
            sx={{
                height: '100vh'
            }}
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
        >
            <Lottie
                animationData={errorAnimation}
                loop={true}
                style={{
                    display: 'flex',
                    maxWidth: '80vw',
                    maxHeight: '80vh'
                }}
            />
            <Typography variant="subtitle1" color={'gray'}>
                Pokušaj ponovno.
            </Typography>
            <Button variant="outlined" onClick={() => window.location.reload()}>
                Osvježi stranicu
            </Button>
        </Stack>
    );
};
